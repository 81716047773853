import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [9],
		"/account/signup": [~10],
		"/admin/accounts": [11,[2]],
		"/admin/components": [12,[2]],
		"/admin/contracts": [13,[2]],
		"/admin/design": [14,[2]],
		"/admin/design/components": [15,[2]],
		"/admin/frames": [16,[2]],
		"/admin/percs-client-components": [17,[2]],
		"/admin/testing": [18,[2]],
		"/creator": [19,[3]],
		"/creator/account": [20,[3]],
		"/creator/audiences": [21,[3,4]],
		"/creator/audiences/contracts/[address]": [23,[3,4]],
		"/creator/audiences/[id]": [22,[3,4]],
		"/creator/automations": [~24,[3]],
		"/creator/campaigns": [25,[3]],
		"/creator/campaigns/edit/[id]": [27,[5]],
		"/creator/campaigns/edit/[id]/campaign-design": [28,[5]],
		"/creator/campaigns/edit/[id]/ticket-design": [29,[5]],
		"/creator/campaigns/new": [30,[3]],
		"/creator/campaigns/[id]": [26,[3]],
		"/creator/contacts": [31,[3]],
		"/creator/contacts/[id]": [32,[3]],
		"/creator/customers": [~33,[3]],
		"/creator/customers/[id]": [~34,[3]],
		"/creator/frames": [35,[3]],
		"/creator/frames/new": [37,[3]],
		"/creator/frames/[id]": [~36,[3]],
		"/creator/messaging": [38,[3]],
		"/creator/settings": [39,[3]],
		"/creator/tickets/preview": [40,[6]],
		"/landing-page-iframe": [~41,[7]],
		"/login": [~42],
		"/login/account": [~44],
		"/login/no_account": [45],
		"/login/profile": [~46],
		"/login/[token]": [~43],
		"/offers": [47],
		"/offers/[id]-[slug]": [~48],
		"/offers/[id]-[slug]/scan": [49],
		"/offers/[id]-[slug]/tickets/[value]": [50],
		"/signup": [~51],
		"/wallet-verification-challenge": [52,[8]],
		"/wallet-verification-challenge/[uuid]": [53,[8]],
		"/wallet-verification-challenge/[uuid]/status": [54,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';